<template>
  <v-menu
    ref="datepickerMenu"
    v-model="menu"
    :return-value.sync="innerValue"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
    nudge-top="15px"
  >
    <template #activator="{ on }">
      <ValidationProvider :name="$attrs.label" :vid="$attrs.vid" :rules="rules">
        <v-text-field
          v-model="innerValue"
          slot-scope="{ errors, valid }"
          :error-messages="errors"
          :success="valid"
          v-bind="$attrs"
          readonly
          hide-details="auto"
          v-on="on"
        />
      </ValidationProvider>
    </template>
    <v-date-picker v-model="innerValue" color="primary" no-title scrollable :type="type">
      <v-spacer />
      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
      <v-btn text color="primary" @click="$refs.datepickerMenu.save(innerValue)">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'DatePickerWithValidation',
  props: {
    rules: {
      type: [
        Object,
        String,
      ],
      default: '',
    },
    // must be included in props
    value: {
      type: null,
      default: null,
    },
    type: {
      type: String,
      default: 'date',
    },
  },
  setup(props, { emit }) {
    const menu = ref(false)
    const innerValue = ref('')

    if (props.value) {
      innerValue.value = props.value
    }

    // Handles internal model changes.
    watch(innerValue, newVal => emit('input', newVal))

    // Handles external model changes.
    watch(
      _ => props.value,
      newVal => (innerValue.value = newVal),
    )

    return {
      menu,
      innerValue,
    }
  },
})
</script>
